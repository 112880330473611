/* eslint-disable react/no-unescaped-entities */
import { graphql } from 'gatsby'
import { Link } from 'gatsby'
import React from 'react'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import '../../less/cms.less'

const PrivacyPolicy = () => {
  return (
    <Layout>
      <SEO title="Privacy Policy" />
      <div className="cms-container">
        <h1 className="cms-container__title">Privacy Policy</h1>
        <div className="cms-container__wrapper cms-container__wrapper__terms">
          <p>
            <strong>Last updated: December 13, 2021.</strong>
          </p>
          <h3>Introduction</h3>
          <p>Welcome to MealPal Limited's privacy notice.</p>
          <p>
            MealPal Limited respects your privacy and is committed to protecting your personal data. This privacy notice
            will inform you as to how we look after your personal data when you visit our website (regardless of where
            you visit it from) and tell you about your privacy rights and how the law protects you.
          </p>

          <h2>1. Important information and who we are</h2>

          <h3>Purpose of this privacy notice</h3>
          <p>
            This privacy notice aims to give you information on how MealPal Limited collects and processes your personal
            data through your use of this website, including any data you may provide through this website when you
            request an invite, purchase a plan or take part in a promotion.
          </p>
          <p>
            It is important that you read this privacy notice together with any other privacy notice or fair processing
            notice we may provide on specific occasions when we are collecting or processing personal data about you so
            that you are fully aware of how and why we are using your data. This privacy notice supplements the other
            notices and is not intended to override them.
          </p>

          <h3>Controller</h3>
          <p>
            MealPal Limited is the controller and is responsible for your personal data (collectively referred to as
            "MealPal", "we", "us" or "our" in this privacy notice).
          </p>
          <p>
            MealPal Limited is made up of different legal entities. This privacy notice is issued on behalf of the
            MealPal Group so when we mention ["MealPal"], "we", "us" or "our" in this privacy notice, we are referring
            to the relevant company in the MealPal Group responsible for processing your data. MealPal Limited is the
            controller and responsible for this website and the services provided to you.
          </p>
          <p>
            If you have any questions about this privacy notice, including any requests to exercise
            <i>your legal rights</i>, please contact us using the details set out below.
          </p>

          <h3>Contact details</h3>
          <p>
            Our full details are:
            <br /> Full name of legal entity: MealPal Limited
            <br /> Name or title of person to contact: Head of Customer Experience
            <br /> Email address: <a href="mailto:privacy@mealpal.com">privacy@mealpal.com</a>
            <br /> Postal address: 264 W. 40th Street, Unit 1303, New York, NY 10018
            <br />
          </p>
          <p>
            You have the right to make a complaint at any time to the Information Commissioner's Office (ICO), the UK
            supervisory authority for data protection issues (www.ico.org.uk). We would, however, appreciate the chance
            to deal with your concerns before you approach the ICO so please contact us in the first instance.
          </p>

          <h3>Changes to the privacy notice and your duty to inform us of changes</h3>
          <p>
            It is important that the personal data we hold about you is accurate and current. Please keep us informed if
            your personal data changes during your relationship with us.
          </p>

          <h3>Third-party links</h3>
          <p>
            This website may include links to third-party websites, plug-ins and applications. Clicking on those links
            or enabling those connections may allow third parties to collect or share data about you. We do not control
            these third-party websites and are not responsible for their privacy statements. When you leave our website,
            we encourage you to read the privacy notice of every website you visit.
          </p>

          <h2>2. The data we collect about you</h2>

          <p>
            Personal data, or personal information, means any information about an individual from which that person can
            be identified. It does not include data where the identity has been removed (anonymous data).
          </p>
          <p>
            We may collect, use, store and transfer different kinds of personal data about you which we have grouped
            together follows:
          </p>
          <ul>
            <li>
              <b>Identity Data</b> includes first name, last name, username or similar identifier.
            </li>
            <li>
              <b>Contact Data</b> includes home address, billing address, employer, employer address, email address and
              telephone numbers.
            </li>
            <li>
              <b>Financial Data</b> includes bank account and payment card details.
            </li>
            <li>
              <b>Transaction Data</b> includes details about payments to and from you and other details of services you
              have purchased from us.
            </li>
            <li>
              <b>Technical Data</b> includes internet protocol (IP) address, your login data, browser type and version,
              location, browser plug-in types and versions, operating system and platform and other technology on the
              devices you use to access this website.
            </li>
            <li>
              <b>Profile Data</b> includes your username and password, your meal plan purchases or orders made by you,
              preferences, feedback and survey responses.
            </li>
            <li>
              <b>Usage Data</b> includes information about how you use our website and services.
            </li>
            <li>
              <b>Marketing and Communications Data</b> includes your preferences in receiving marketing from us and our
              third parties and your communication preferences.
            </li>
          </ul>
          <p>
            We also collect, use and share
            <b>Aggregated Data</b> such as statistical or demographic data for any purpose. Aggregated Data may be
            derived from your personal data but is not considered personal data in law as this data does
            <b>not</b> directly or indirectly reveal your identity. For example, we may aggregate your Usage Data to
            calculate the percentage of users accessing a specific website feature. However, if we combine or connect
            Aggregated Data with your personal data so that it can directly or indirectly identify you, we treat the
            combined data as personal data which will be used in accordance with this privacy notice.
          </p>
          <p>
            We do not collect any
            <b>Special Categories of Personal Data</b> about you (this includes details about your race or ethnicity,
            religious or philosophical beliefs, sex life, sexual orientation, political opinions, trade union
            membership, information about your health and genetic and biometric data). Nor do we collect any information
            about criminal convictions and offences.
          </p>
          <p>
            <b>If you fail to provide personal data</b>
            <br /> Where we need to collect personal data by law, or under the terms of a contract we have with you and
            you fail to provide that data when requested, we may not be able to perform the contract we have or are
            trying to enter into with you (for example, to provide you with services). In this case, we may have to
            cancel a service you have with us but we will notify you if this is the case at the time.
          </p>

          <h2>3. How is your personal data collected?</h2>

          <p>We use different methods to collect data from and about you including through:</p>
          <ul>
            <li>
              <b>Direct interactions.</b> You may give us your Identity, Contact and Financial Data by filling in forms
              on our website or by corresponding with us by email or otherwise. This includes personal data you provide
              when you:
              <ul>
                <li>apply for our services;</li>
                <li>create an account on our website;</li>
                <li>subscribe to our service or publications;</li>
                <li>request marketing to be sent to you;</li>
                <li>enter a competition, promotion or survey; or</li>
                <li>give us some feedback</li>
              </ul>
            </li>
            <li>
              Automated technologies or interactions. As you interact with our website, we may automatically collect
              Technical Data about your equipment, browsing actions and patterns. We collect this personal data by using
              cookies and other similar technologies. Please see our <Link to="/uk/cookie-policy">cookie policy</Link>{' '}
              for further details.
            </li>
            <li>
              <b>Third parties.</b> We may receive personal data about you from various third parties as set out below:
              <ul>
                <li>Technical Data from analytics providers such as Google based outside the EU; and</li>
                <li>Identity Data from data brokers or aggregators such as Facebook based outside the EU.</li>
              </ul>
            </li>
          </ul>

          <h2>4. How we use your personal data</h2>

          <p>
            We will only use your personal data when the law allows us to. Most commonly, we will use your personal data
            in the following circumstances:
          </p>
          <ul>
            <li>Where we need to perform the contract we are about to enter into or have entered into with you.</li>
            <li>
              Where it is necessary for our legitimate interests (or those of a third party) and your interests and
              fundamental rights do not override those interests.
            </li>
            <li>Where we need to comply with a legal or regulatory obligation.</li>
          </ul>
          <p>
            Generally we do not rely on consent as a legal basis for processing your personal data other than in
            relation to sending third party direct marketing communications to you via email or text message. You have
            the right to withdraw consent to marketing at any time.
          </p>

          <h3>Purposes for which we will use your personal data</h3>

          <p>
            We have set out below, in a table format, a description of all the ways we plan to use your personal data,
            and which of the legal bases we rely on to do so. We have also identified what our legitimate interests are
            where appropriate.
          </p>
          <p>
            Note that we may process your personal data for more than one lawful ground depending on the specific
            purpose for which we are using your data. Please contact us if you need details about the specific legal
            ground we are relying on to process your personal data where more than one ground has been set out in the
            table below.
          </p>
          <div className="table-responsive">
            <table className="privacy-table">
              <thead>
                <tr>
                  <th>
                    <b>Purpose/Activity</b>
                  </th>
                  <th>
                    <b>Type of data</b>
                  </th>
                  <th>
                    <b>Lawful basis for processing including basis of legitimate interest</b>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>To register you as a new customer</td>
                  <td>
                    (a) Identity
                    <br /> (b) Contact
                  </td>
                  <td>Performance of a contract with you</td>
                </tr>
                <tr>
                  <td>
                    To process and deliver your order including:
                    <br /> (a) Manage payments, fees and charges
                    <br /> (b) Collect and recover money owed to us
                  </td>
                  <td>
                    (a) Identity
                    <br /> (b) Contact
                    <br /> (c) Financial
                    <br /> (d) Transaction
                    <br /> (e) Marketing and Communications
                  </td>
                  <td>
                    (a) Performance of a contract with you
                    <br /> (b) Necessary for our legitimate interests (to recover debts due to us)
                  </td>
                </tr>
                <tr>
                  <td>
                    To manage our relationship with you which will include:
                    <br /> (a) Notifying you about changes to our terms or privacy policy
                    <br /> (b) Asking you to leave a review or take a survey
                  </td>
                  <td>
                    (a) Identity
                    <br /> (b) Contact
                    <br /> (c) Profile
                    <br /> (d) Marketing and Communications
                  </td>
                  <td>
                    (a) Performance of a contract with you
                    <br /> (b) Necessary to comply with a legal obligation
                    <br /> (c) Necessary for our legitimate interests (to keep our records updated and to study how
                    customers use our services)
                  </td>
                </tr>
                <tr>
                  <td>To enable you to partake in a competition or complete a survey</td>
                  <td>
                    (a) Identity
                    <br /> (b) Contact
                    <br /> (c) Profile
                    <br /> (d) Usage
                    <br /> (e) Marketing and Communications
                  </td>
                  <td>
                    (a) Performance of a contract with you
                    <br /> (b) Necessary for our legitimate interests (to study how customers use our services, to
                    develop them and grow our business)
                  </td>
                </tr>
                <tr>
                  <td>
                    To administer and protect our business and this website (including troubleshooting, data analysis,
                    testing, system maintenance, support, reporting and hosting of data)
                  </td>
                  <td>
                    a) Identity
                    <br /> (b) Contact
                    <br /> (c) Technical
                  </td>
                  <td>
                    (a) Necessary for our legitimate interests (for running our business, provision of administration
                    and IT services, network security, to prevent fraud and in the context of a business reorganisation
                    or group restructuring exercise)
                    <br /> (b) Necessary to comply with a legal obligation
                  </td>
                </tr>
                <tr>
                  <td>
                    To deliver relevant website content and advertisements to you and measure or understand the
                    effectiveness of the advertising we serve to you
                  </td>
                  <td>
                    (a) Identity
                    <br /> (b) Contact
                    <br /> (c) Profile
                    <br /> (d) Usage
                    <br /> (e) Marketing and Communications
                    <br /> (f) Technical
                  </td>
                  <td>
                    Necessary for our legitimate interests (to study how customers use our services, to develop them, to
                    grow our business and to inform our marketing strategy)
                  </td>
                </tr>
                <tr>
                  <td>
                    To use data analytics to improve our website, services, marketing, customer relationships and
                    experiences
                  </td>
                  <td>
                    (a) Technical
                    <br /> (b) Usage
                  </td>
                  <td>
                    Necessary for our legitimate interests (to define types of customers for our services, to keep our
                    website updated and relevant, to develop our business and to inform our marketing strategy)
                  </td>
                </tr>
                <tr>
                  <td>To enable you to partake in a free trial of our services, competition or complete a survey</td>
                  <td>
                    (a) Identity
                    <br /> (b) Contact
                    <br /> (c) Profile
                    <br /> (d) Usage
                    <br /> (e) Financial
                    <br /> (e) Marketing and Communications
                  </td>
                  <td>
                    (a) Performance of a contract with you
                    <br /> (b) Necessary for our legitimate interests - to study how customers use our services, to
                    develop them and grow our business.
                    <br /> (c) It is also necessary to monitor individuals signing up for free trial services and to
                    ensure our free trials are being used fairly and not recurrently.
                  </td>
                </tr>
                <tr>
                  <td>
                    To make suggestions and recommendations to you about goods or services that may be of interest to
                    you
                  </td>
                  <td>
                    (a) Identity
                    <br /> (b) Contact
                    <br /> (c) Technical
                    <br /> (d) Usage
                    <br /> (e) Profile
                  </td>
                  <td>Necessary for our legitimate interests (to develop our services and grow our business)</td>
                </tr>
              </tbody>
            </table>
          </div>

          <h3>Marketing</h3>
          <p>
            We strive to provide you with choices regarding certain personal data uses, particularly around marketing
            and advertising.
          </p>

          <h3>Promotional offers from us</h3>
          <p>
            We may use your Identity, Contact, Technical, Usage and Profile Data to form a view on what we think you may
            want or need, or what may be of interest to you. This is how we decide which services and offers may be
            relevant for you (we call this marketing).
          </p>
          <p>
            You will receive marketing communications from us if you have requested information from us or purchased
            services from us and, in each case, you have not opted out of receiving that marketing.
          </p>

          <h3>Third-party marketing</h3>
          <p>
            We will get your express opt-in consent before we share your personal data with any company outside the
            MealPal group of companies for marketing purposes.
          </p>

          <h3>Opting out</h3>
          <p>
            You can ask us or third parties to stop sending you marketing messages at any time by logging into the
            website and checking or unchecking relevant boxes to adjust your marketing preferences or by following the
            opt-out links on any marketing message sent to you.
          </p>
          <p>
            Where you opt out of receiving these marketing messages, this will not apply to personal data provided to us
            as a result of service purchase.
          </p>

          <h3>Change of purpose</h3>
          <p>
            We will only use your personal data for the purposes for which we collected it, unless we reasonably
            consider that we need to use it for another reason and that reason is compatible with the original purpose.
            If you wish to get an explanation as to how the processing for the new purpose is compatible with the
            original purpose, please contact us.
          </p>
          <p>
            If we need to use your personal data for an unrelated purpose, we will notify you and we will explain the
            legal basis which allows us to do so.
          </p>
          <p>
            Please note that we may process your personal data without your knowledge or consent, in compliance with the
            above rules, where this is required or permitted by law.
          </p>

          <h2>5. Disclosures of your personal data</h2>

          <p>
            We may have to share your personal data with the parties set out below for the purposes set out in the table
            in paragraph 4 above.
          </p>
          <ul>
            <li>MealPal Inc. located outside of the EEA</li>
            <li>Third parties such as:</li>
            <li>Google Inc. located outside of the EEA;</li>
            <li>MealPal's partnered restaurants which are found on our website, located within the EEA</li>
            <li>
              Third parties to whom we may choose to sell, transfer, or merge parts of our business or our assets.
              Alternatively, we may seek to acquire other businesses or merge with them. If a change happens to our
              business, then the new owners may use your personal data in the same way as set out in this privacy
              notice.
            </li>
          </ul>
          <p>
            We require all third parties to respect the security of your personal data and to treat it in accordance
            with the law. We do not allow our third-party service providers to use your personal data for their own
            purposes and only permit them to process your personal data for specified purposes and in accordance with
            our instructions.
          </p>

          <h2>6. International transfers</h2>

          <p>
            We share your personal data within the MealPal Group. This will involve transferring your data outside the
            European Economic Area
            <b>(EEA)</b>.
          </p>
          <p>
            We ensure your personal data is protected by requiring all our group companies to follow the same rules when
            processing your personal data. These rules are called "binding corporate rules". For further details, see
            European Commission: Binding corporate rules.
          </p>
          <p>
            Whenever we transfer your personal data out of the EEA, we ensure a similar degree of protection is afforded
            to it by ensuring at least one of the following safeguards is implemented:
          </p>
          <ul>
            <li>
              We will only transfer your personal data to countries that have been deemed to provide an adequate level
              of protection for personal data by the European Commission. For further details, see European Commission:
              Adequacy of the protection of personal data in non-EU countries.
            </li>
            <li>
              Where we use providers based in the US, we may transfer data to them if they are part of the Privacy
              Shield which requires them to provide similar protection to personal data shared between the Europe and
              the US. For further details, see European Commission: EU-US Privacy Shield.
            </li>
          </ul>

          <p>
            Please contact us if you want further information on the specific mechanism used by us when transferring
            your personal data out of the EEA.
          </p>

          <h2>7. Data security</h2>

          <p>
            We have put in place appropriate security measures to prevent your personal data from being accidentally
            lost, used or accessed in an unauthorised way, altered or disclosed. In addition, we limit access to your
            personal data to those employees, agents, contractors and other third parties who have a business need to
            know. They will only process your personal data on our instructions and they are subject to a duty of
            confidentiality.
          </p>
          <p>
            We have put in place procedures to deal with any suspected personal data breach and will notify you and any
            applicable regulator of a breach where we are legally required to do so.
          </p>

          <h2>8. Data retention</h2>

          <p>
            We will only retain your personal data for as long as necessary to fulfil the purposes we collected it for,
            including for the purposes of satisfying any legal, accounting, or reporting requirements.
          </p>
          <p>
            To determine the appropriate retention period for personal data, we consider the amount, nature, and
            sensitivity of the personal data, the potential risk of harm from unauthorised use or disclosure of your
            personal data, the purposes for which we process your personal data and whether we can achieve those
            purposes through other means, and the applicable legal requirements.
          </p>
          <p>
            In some circumstances you can ask us to delete your data: see Request erasure below for further information.
          </p>
          <p>
            In some circumstances we may anonymise your personal data (so that it can no longer be associated with you)
            for research or statistical purposes in which case we may use this information indefinitely without further
            notice to you.
          </p>

          <h2>9. Your legal rights</h2>

          <p>
            Under certain circumstances, you have rights under data protection laws in relation to your personal data.
          </p>
          <p>You have the right to:</p>
          <ul>
            <li>
              Request access to your personal data (commonly known as a "data subject access request"). This enables you
              to receive a copy of the personal data we hold about you and to check that we are lawfully processing it.
            </li>
            <li>
              Request correction of the personal data that we hold about you. This enables you to have any incomplete or
              inaccurate data we hold about you corrected, though we may need to verify the accuracy of the new data you
              provide to us.
            </li>
            <li>
              Request erasure of your personal data. This enables you to ask us to delete or remove personal data where
              there is no good reason for us continuing to process it. You also have the right to ask us to delete or
              remove your personal data where you have successfully exercised your right to object to processing (see
              below), where we may have processed your information unlawfully or where we are required to erase your
              personal data to comply with local law. Note, however, that we may not always be able to comply with your
              request of erasure for specific legal reasons which will be notified to you, if applicable, at the time of
              your request.
            </li>
            <li>
              Object to processing of your personal data where we are relying on a legitimate interest (or those of a
              third party) and there is something about your particular situation which makes you want to object to
              processing on this ground as you feel it impacts on your fundamental rights and freedoms. You also have
              the right to object where we are processing your personal data for direct marketing purposes. In some
              cases, we may demonstrate that we have compelling legitimate grounds to process your information which
              override your rights and freedoms.
            </li>
            <li>
              Request restriction of processing of your personal data. This enables you to ask us to suspend the
              processing of your personal data in the following scenarios: (a) if you want us to establish the data's
              accuracy; (b) where our use of the data is unlawful but you do not want us to erase it; (c) where you need
              us to hold the data even if we no longer require it as you need it to establish, exercise or defend legal
              claims; or (d) you have objected to our use of your data but we need to verify whether we have overriding
              legitimate grounds to use it.
            </li>
            <li>
              Request the transfer of your personal data to you or to a third party. We will provide to you, or a third
              party you have chosen, your personal data in a structured, commonly used, machine-readable format. Note
              that this right only applies to automated information which you initially provided consent for us to use
              or where we used the information to perform a contract with you.
            </li>
            <li>
              Withdraw consent at any time where we are relying on consent to process your personal data. However, this
              will not affect the lawfulness of any processing carried out before you withdraw your consent. If you
              withdraw your consent, we may not be able to provide certain services to you. We will advise you if this
              is the case at the time you withdraw your consent.
            </li>
          </ul>

          <h3>No fee usually required</h3>
          <p>
            You will not have to pay a fee to access your personal data (or to exercise any of the other rights).
            However, we may charge a reasonable fee if your request is clearly unfounded, repetitive or excessive.
            Alternatively, we may refuse to comply with your request in these circumstances.
          </p>

          <h3>What we may need from you</h3>
          <p>
            We may need to request specific information from you to help us confirm your identity and ensure your right
            to access your personal data (or to exercise any of your other rights). This is a security measure to ensure
            that personal data is not disclosed to any person who has no right to receive it. We may also contact you to
            ask you for further information in relation to your request to speed up our response.
          </p>

          <h3>Time limit to respond</h3>
          <p>
            We try to respond to all legitimate requests within one month. Occasionally it may take us longer than a
            month if your request is particularly complex or you have made a number of requests. In this case, we will
            notify you and keep you updated.
          </p>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default PrivacyPolicy
